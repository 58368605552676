import React from 'react';
import FaqRoot from '../views/FAQ/FaqRoot';
import Layout from '../layout/Layout';
import { Seo } from '../components/Seo'
import { Link } from 'gatsby';

const FaqPage = () => (
	<Layout>
		<Seo
			metaDescription={"We have answered some commonly asked questions. Feel free to contact us if you have any other questions."}
			title={"Frequently Asked Questions"}
			slug={'/faq'}
		/>
		<FaqRoot faq={faq} />
	</Layout>
);

export default FaqPage;

const faq = [
	{
		question: 'What is DeReader?',
		answer:
			'DeReader is a management and documentation platform for assets. Users can upload their asset information and relevant files to the DeReader database which can be accessed through our mobile apps and website.',
		key: '0'
	},
	{
		question: 'What can DeReader be used for?',
		answer:
			'Some examples of possible assets are asset information, valve specifications, restaurant menus, car maintenance and warehouse inventories.',
		key: '1'
	},
	{
		question: 'Where will my asset information be stored?',
		answer:
			'DeReader has built a secure and stable database that is hosted on a cloud server. All asset and user information is stored on the database. The database allows DeReader to protect app data, manage ownership rights for assets and query asset information.',
		key: '2'
	},
	{
		question: 'Is my asset information secure?',
		answer:
			'All asset and user information on DeReader is encrypted and only accessible to users with the appropriate permissions. All data passed through the internet is protected through a Secure Sockets Layer (SSL).  Only users of the app will be able to access asset information. Security is the most important aspect of DeReader and we have taken measures to ensure all information is secure.',
		key: '3'
	},
	{
		question: 'What if you lose the asset data?',
		answer:
			'DeReader makes periodic copies of the database on alternate servers to ensure no data is lost if the worst were to happen.',
		key: '4'
	},
	{
		question: 'Will the data stand the test of time?',
		answer:
			'All asset information will be periodically archived in a seperate database that is designed to be extremely sturdy and store information for extended periods of time. DeReader will ensure that asset data will be available for centuries to come. ',
		key: '5'
	},
	{
		question: 'Is the data encrypted?',
		answer:
			'All data in the DeReader database is encrypted and can only be decrypted by users with the appropriate permissions.',
		key: '6'
	},
	{
		question: 'Are the QR codes protected?',
		answer:
			'QR codes generated by DeReader are encrypted and can only be accessed by users of the app that have the DeReader Mobile app.. The QR codes are not readable by regular QR code scanners.',
		key: '7'
	},
	{
		question: 'Are the payments secure?',
		answer: (
			<span>
				Payments in DeReader app are handled by a credit card processor called Stripe. Stripe is a secure and
				widely used payment solution. You can visit their{' '}
				<a href='https://stripe.com/ca/' target='_blank' rel='noreferrer noopener'>
					Terms of Services
				</a>
				.
			</span>
		),
		key: '8'
	},
	{
		question: 'Can anyone access my QR codes?',
		answer:
			'Users who have scanned your QR code will have READ-only access to the asset information and will not be able to modify it in any manner.',
		key: '9'
	},
	{
		question: 'Can anyone modify my QR codes?',
		answer: 'ONLY the owners of a QR code can modify its contents and asset information.',
		key: '10'
	},
	{
		question: 'Do you collect information about your users?',
		answer: (
			<span>
				We collect user information that is used for the base functionality of the app. We also collect app
				usage analytics that allows us to build a stronger user experience and improve the quality of the app.
				You can visit our <Link to='/terms-of-service'>Terms and Services</Link> and{' '}
				<Link to='exclude/privacy-policy'>Privacy Policy</Link> for more information.,
			</span>
		),
		key: '11'
	},
	{
		question: 'Are passwords encrypted?',
		answer:
			'All passwords and user information on DeReader are encrypted. Passwords are not stored on our database and are inaccessible by DeReader admins.',
		key: '12'
	},
	{
		question: 'Is the server safe from malicious attacks?',
		answer:
			'DeReader has taken steps to ensure our server is safe from malicious attacks. We have set precautions against DDOS attacks, multiple login attempts and other common security risks.',
		key: '13'
	},
	{
		question: 'Does DeReader have access to my data?',
		answer: (
			<span>
				DeReader has access to all asset and user information in the database. We require access in order to
				audit asset information and to facilitate asset transfers between companies. DeReader is also obligated
				to provide asset information if we are asked to by the court of law. You can visit our{' '}
				<Link to='exclude/privacy-policy'>Privacy Policy</Link> for more information.
			</span>
		),
		key: '14'
	}
];
